import './App.css';;



function App() {
  return (
    <div className="App">
     <div className='box'>
        <div>
          <p className='paragraf'>Let professional technology protect you and enjoy a "smart" life - DCPTG</p>
        </div>
        <div className='title'>
          <h3>Guys, I always had a problem to start, I'm finally making extra money, currently i earn 100 dollars a day and all payment go to Tangem or Binance very easy! </h3>
        </div>
        <div>
          <h3>Join my team</h3>
          <a id='blink_text' className='link' href='https://www.dcptgt.xyz/?tid=FV2I4K'>Link</a>
          
          <div id='' className='link1'>
            <p>or Copy</p>
            https://www.dcptgt.xyz/?tid=FV2I4K
            
          </div>
        </div>
     </div>
      <h4 className='h3'> DCPTG is a quantitative trading platform that provides investors with tools for quantitative trading and services, and help them do better.</h4>
      <div className='img'>

        The main business of DCPTG is to combine With the capabilities of big data, artificial intelligence and cloud computing, it provides quantitative investors and investment institutions with industry-leading new data and intelligent technology services, covering machine learning, strategy development, backtesting, trading and risk control. Through machine learning and other artificial intelligence technologies to realize artificial intelligence quantitative investment, improve investment efficiency, and provide users with new quantitative big data and intelligent technology services, DCPTG aims to become an artificial intelligence quantitative trading platform and community for each user. Here, quantitative investors can use the most advanced artificial intelligence technology without threshold. There is no need to learn a lot of difficult programming and algorithm knowledge. <a href="https://online-training-ugo.netlify.app/">Webprogrammers</a>
      
      </div>

      <div className='footer'>
        Copyright ℗ webprogrammers 2024 Ⓒ space-flow ∞ Hugo
      </div>
    </div>
  );
}

export default App;
